// BootstrapのJavaScript側の機能を読み込む
import "bootstrap";

// スタイルシートを読み込む
import "./index.scss";

// カスタムスクリプト
$(function () {
  // --- ドロワーメニュー
  // --- https://mdstage.com/web/jquery/drawer-menu
  $('.navbar_toggle').on('click', function () {
    $(this).toggleClass('open');
    $('.menu').toggleClass('open');
  });
  // --- toPageTop スクリプト
  var topBtn = $('#toPageTop');
  topBtn.hide();
  // --- スクロールが500に達したらボタン表示
  $(window).scroll(function () {
    if ($(this).scrollTop() > 500) {
      topBtn.fadeIn();
    } else {
      topBtn.fadeOut();
    }
  });
  topBtn.click(function () {
    $('body,html').animate({
      scrollTop: 0
    }, 500);
    return false;
  });
  // --- Movie レスポンシブ
  // 切り替える対象にclass属性。
  var $elem = $('.switch');
  var video = document.getElementById('home-movie-item');
  // 切り替えの対象のsrc属性の末尾の文字列。
  var sp = '_sp.';
  var pc = '_pc.';
  // 画像を切り替えるウィンドウサイズ。
  var replaceWidth = 768;

  function imageSwitch() {
    // ウィンドウサイズを取得する。
    var windowWidth = parseInt($(window).width());
    // ページ内にあるすべての`.switch`に適応される。
    $elem.each(function () {
      var $this = $(this);
      if (windowWidth >= replaceWidth) {
        // ウィンドウサイズが768px以上であれば_spを_pcに置換する。
        $this.attr('src', $this.attr('src').replace(sp, pc));
      } else {
        // ウィンドウサイズが768px未満であれば_pcを_spに置換する。
        $this.attr('src', $this.attr('src').replace(pc, sp));
      }
      video.load();
    });
  }
  imageSwitch();
  // 動的なリサイズは操作後0.2秒経ってから処理を実行する。
  // var resizeTimer;
  // $(window).on('resize', function () {
  //   clearTimeout(resizeTimer);
  //   resizeTimer = setTimeout(function () {
  //     imageSwitch();
  //   }, 200);
  // });
  // スマートフォン時、.tel-link クラスに a 要素を追加する ※タップしてTELすることができる
  var device = navigator.userAgent;
  if ((device.indexOf('iPhone') > 0 && device.indexOf('iPad') == -1) || device.indexOf('iPod') > 0 || device.indexOf('Android') > 0) {
    $('.tel-link').each(function () {
      var str = $(this).text();
      $(this).html($('<a class="">').attr('href', 'tel:' + str.replace(/-/g, '')).append(str + '</a>'));
    });
  }
});